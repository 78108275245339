import { render, staticRenderFns } from "./pdfList.vue?vue&type=template&id=21323014&scoped=true&"
import script from "./pdfList.vue?vue&type=script&lang=js&"
export * from "./pdfList.vue?vue&type=script&lang=js&"
import style0 from "./pdfList.vue?vue&type=style&index=0&id=21323014&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21323014",
  null
  
)

export default component.exports