<!--
 * @Author: steven 1105624290@qq.com
 * @Date: 2022-07-22 10:39:37
 * @LastEditors: steven 1105624290@qq.com
 * @LastEditTime: 2022-07-27 10:35:51
 * @FilePath: /ytjj-mobile/src/views/pd/details/pdfList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="pdf_list">
        <div class="header">
            <div class="plan_id">
                <van-icon @click="goback" class="icon_top" name="arrow-left" />
                <div>附件资源</div>
                <van-icon class="icon_top1" name="ellipsis" />
            </div>
        </div>
        <div class="body">
            <div @click="goPdf(item)" class="item" v-for="(item,index) in PDFList" :key="index">
                <!-- <a :href="item.resourceUrl" class="item" v-for="(item,index) in PDFList" :key="index"> -->
                    <div class="left">
                        <img class="file" src="@/assets/pd/Layer 27@2x.png" alt="">
                    </div>
                    <div class="right">
                        {{ item.resourceName }}
                    </div>
                <!-- </a> -->
                
            </div>
        </div>
    </div>
</template>
<script>
import { coursePDF } from "@/api/pd/index";
export default {
    data () {
        return {
            PDFList:[],
        }
    },
    methods: {
        goPdf(item){
            console.log(location.href)
            window.location.href = item.resourceUrl;
        },
        goback(){
            this.$router.go(-1)
        },
        // 获取所有的上传的pdf
        queryPDF() {
            let obj = {
                courseId: this.$route.params.id,
            };
            coursePDF(obj).then((res) => {
                this.PDFList = res.data;
            });
        },
    },
    mounted() {
        this.queryPDF()
    },
}
</script>

<style lang="scss" scoped>
    .pdf_list{
        width: 100%;
        height: 100%;
        font-size: 30px;

        .header{
            position: fixed;
            left: 0;
            top: 0;
            height: 90px;
            width: 100%;
            background-color: #4DB3B3;
            padding: 23px 43px 10px 43px;
            box-sizing: border-box;
            color: #FFFFFF;
            z-index: 100;

            .plan_id{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 36px;
                font-weight: bold;

                .icon_top{
                    font-size: 40px;
                    font-weight: bold;
                }
                
                .icon_top1{
                    opacity: 0;
                }
            }
        }

        .body{
            width: 100%;
            position: absolute;
            top: 90px;
            bottom: 0;
            overflow-y: scroll;
            box-sizing: border-box;
            padding: 30px 32px 0 32px;
            box-sizing: border-box;
            
            .item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 130px;
                // border: 1px solid red;

                .left{
                    .file{
                        width: 88px;
                        height: 88px;
                    }
                }

                .right{
                    display: flex;
                    align-items: center;
                    width: 80%;
                    height: 130px;
                    color: #606060;
                    font-size: 30px;
                }
            }
        }
    }
</style>
